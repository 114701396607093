$(document).foundation();
$(document).ready(function () {
    initSliders();
    initGalleries();
    initBookingWidget();
    initFilters();
    initBlog();
    initToggleInView();
    initNewsletter();
    initRatingColors();
    initOffersGrid();
    resizeVideoEmbeds();
    initForms();
});


$('.business-list ul .mobile a').on('click', function (event) {

	$(this).next('.hidden').toggle();
	
	event.preventDefault()

});


function initOffersGrid() {
    if ( $('.flexible-offer-grid__item').length % 2 ) {
        $('.flexible-offer-grid__item').last().addClass('flexible-offer-grid__item--wide');
    }
}

function resizeVideoEmbeds() {
    $('iframe[src*="youtube.com/embed"], iframe[src*="player.vimeo.com/video"]').each(function () {
        $(this).wrap('<div class="videoWrapper"></div>');
    });
}


function initRatingColors() {
    $('.landing-grid__item[data-hotel="the-royal-oak-keswick"]').addClass('gold-star');
    $('.landing-grid__item[data-hotel="the-lister-arms-malham"]').addClass('gold-star');
    $('.landing-grid__item[data-hotel="crown-inn-pooley-bridge"]').addClass('silver-star');
    $('.landing-grid__item[data-hotel="the-golden-lion-settle"]').addClass('silver-star');
    $('.landing-grid__item[data-hotel="langdale-chase-windermere"]').addClass('silver-star');
    $('.landing-grid__item[data-hotel="cottons-knutsford"]').addClass('silver-star');
    $('.landing-grid__item[data-hotel="the-beverley-arms-beverley"]').addClass('silver-star');
}

function initSliders() {
    $('[data-slick]').slick({
        prevArrow: '<button type="button" class="slick-prev"><i class="icon-chevron-thin-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="icon-chevron-thin-right"></i></button>'
    });
}

function initGalleries() {
    $('[data-gallery]').on('click', function () {
        var activeSlideIndex = $(this).data('slide-index') || $(this).next().find('.slick-active').index();

        $('.gallery-popup__inner')
            .empty()
            .load($(this).data('gallery'), function () {
                $('.gallery-popup .slider').slick({
                    initialSlide: Math.max(activeSlideIndex, 0),
                    prevArrow: '<button type="button" class="slick-prev"><i class="icon-chevron-thin-left"></i></button>',
                    nextArrow: '<button type="button" class="slick-next"><i class="icon-chevron-thin-right"></i></button>'
                });
            });

        $('.gallery-popup').foundation('open');
    });

    $('.gallery-popup').on('closed.zf.reveal', function () {
        // empty the popup to stop any videos playing
        $('.gallery-popup__inner').empty();
    });
}

function initBookingWidget() {

    // date pickers
    var today = new Date();

    $('.booking-widget__datepicker input').each(function (i, el) {
        $(el).fdatepicker({
            startDate: today,
            weekStart: 1,
            format: 'dd/mm/yyyy',
            leftArrow: '<i class="icon-chevron-thin-left"></i>',
            rightArrow: '<i class="icon-chevron-thin-right"></i>',
            appendTo: $(el).parent(),
            onRender: function (date) {
                if (this.element.attr('name') !== 'dateOut') {
                    return '';
                }

                var dateIn = this.element.closest('form').find('input[name="dateIn"]').data('datepicker');

                return date.valueOf() <= dateIn.date.valueOf() ? 'disabled' : '';
            }
        });
    });

    $('.booking-widget__datepicker input[name="dateIn"]').on('changeDate', function (e) {
        var dateOut = $(this).closest('form').find('input[name="dateOut"]').data('datepicker');

        if (e.date.valueOf() > dateOut.date.valueOf()) {
            // update departure date to be later than arrival date
            var newDate = new Date(e.date);
            newDate.setDate(newDate.getDate() + 1);
            dateOut.update(newDate);
        }
    });

    $('.booking-widget form').on('submit', function (e) {
        // set action URL based on selected hotel
        var hotelId = $(this).find('select[name="hotelId"]').val();

        if (hotelId.length === 0) {
            // no hotel selected
            e.preventDefault();
            return false;
        }

        $(this).attr('action', $(this).data('action') + hotelId);

        // change date values to match TravelClick format (mm/dd/yyyy)
        var dateIn = $(this).find('input[name="dateIn"]');
        var dateOut = $(this).find('input[name="dateOut"]');

        var dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;

        var dateInVal = dateIn.val();

        if (dateRegex.test(dateInVal)) {
            var parts = dateRegex.exec(dateInVal);

            dateIn.val(parts[2] + '/' + parts[1] + '/' + parts[3]);
        }

        var dateOutVal = dateOut.val();

        if (dateRegex.test(dateOutVal)) {
            var parts = dateRegex.exec(dateOutVal);

            dateOut.val(parts[2] + '/' + parts[1] + '/' + parts[3]);
        }

        return true;
    });
}

function initFilters() {
    $('.filters__view .filters__button').on('click', function (e) {
        var view = $(this).val();

        // change grid style
        $('.landing-grid')
            .removeClass('landing-grid--list landing-grid--grid landing-grid--map')
            .addClass('landing-grid--' + view);

        // set active filter button
        $(this)
            .siblings('.filters__button')
            .removeClass('active')
            .end()
            .addClass('active');

        // toggle elements for the map view
        if (view === 'map') {
            $('.intro').hide();
            $('.landing-grid__map').trigger('resizeme.zf.trigger');
            $('.filters .filters__list')
                .addClass('filters__list--accordion')
                .first()
                .removeClass('filters__list--hidden');
        } else {
            $('.intro').show();
            $('.filters .filters__list')
                .removeClass('filters__list--accordion active')
                .first()
                .addClass('filters__list--hidden');

            // deselect any hotel filters
            $('.filters input[name="hotel"]:checked')
                .prop('checked', false)
                .last()
                .trigger('change');
        }
    });

    $('.filters__list li:first-child').on('click', function (e) {
        var list = $(this).parent('ul');

        if (!list.hasClass('filters__list--accordion') && Foundation.MediaQuery.atLeast('large')) {
            // only change when accordion is enabled or on small screens
            return;
        }

        // toggle filter visibility
        list.toggleClass('active');
    });

    $('.filters__heading').on('click', function (e) {
        // show or hide the filters (mobile only)
        var isActive = $(this).hasClass('active');

        $(this)
            .toggleClass('active')
            .siblings('.filters__list, .filters__close')
            .css('display', isActive ? 'none' : 'block');
    });

    $('.filters__close').on('click', function (e) {
        // close filters
        $('.filters__heading').trigger('click');
    });

    // filter functions
    $('.filters input[type="checkbox"]').on('change', function (e) {
        filterItems();
    });

    var filterItems = function () {
        var filterHash = '';

        // clear any hidden items
        $('.landing-grid__item').unwrap('hidden');

        var selectedHotels = $('.filters input[name="hotel"]:checked').map(function (i, el) { return el.value; });

        if (selectedHotels.length > 0) {
            // hide hotels that don't match the selector
            var hotelSelector = '[data-hotel!="' + selectedHotels.toArray().join('"][data-hotel!="') + '"]';

            $('.landing-grid__item' + hotelSelector).unwrap('hidden').wrap('<hidden>');

            filterHash += (filterHash.length > 0 ? '&' : '') + 'hotel=' + selectedHotels.toArray().join(',');
        }

        var selectedLocations = $('.filters input[name="location"]:checked').map(function (i, el) { return el.value; });

        if (selectedLocations.length > 0) {
            // hide hotels that don't match the selector
            var locationSelector = '[data-location!="' + selectedLocations.toArray().join('"][data-location!="') + '"]';

            $('.landing-grid__item' + locationSelector).unwrap('hidden').wrap('<hidden>');

            filterHash += (filterHash.length > 0 ? '&' : '') + 'location=' + selectedLocations.toArray().join(',');
        }

        var selectedSuitables = $('.filters input[name="suitable"]:checked').map(function (i, el) { return el.value; });

        if (selectedSuitables.length > 0) {
            // hide hotels that don't match the selector
            var suitableSelector = ':not([data-suitable~="' + selectedSuitables.toArray().join('"], [data-suitable~="') + '"])';

            $('.landing-grid__item' + suitableSelector).unwrap('hidden').wrap('<hidden>');

            filterHash += (filterHash.length > 0 ? '&' : '') + 'suitable=' + selectedSuitables.toArray().join(',');
        }

        var selectedFacilities = $('.filters input[name="facilities"]:checked').map(function (i, el) { return el.value; });

        if (selectedFacilities.length > 0) {
            // hide hotels that don't match the selector
            var facilitiesSelector = ':not([data-facilities~="' + selectedFacilities.toArray().join('"], [data-facilities~="') + '"])';

            $('.landing-grid__item' + facilitiesSelector).unwrap('hidden').wrap('<hidden>');

            filterHash += (filterHash.length > 0 ? '&' : '') + 'facilities=' + selectedFacilities.toArray().join(',');
        }

        // refresh the slick sliders in the grid items
        $('.landing-grid__item .slick-initialized')
            .slick('refresh')
            .slick('reinit');

        // refresh the map
        $('#mapLocationSrc').trigger('markers.refresh');

        // set URL with filters
        history.replaceState(undefined, undefined, '#' + filterHash);
    };

    // pre-load any filters based on the URL hash
    if (window.location.hash.length > 1) {
        var filters = window.location.hash.substr(1).split('&');

        filters.forEach(function (f) {
            var filter = f.split('=');

            if (filter.length < 2) {
                return;
            }

            var filterName = filter[0];
            var filterValues = filter[1].split(',');

            $('.filters__list input[name="' + filterName + '"]')
                .filter('[value="' + filterValues.join('"], [value="') + '"]')
                .prop('checked', true);
        });

        filterItems();
    }
}

function initBlog() {
    if ($('.blog-grid').length === 0) {
        return;
    }

    $('.blog-grid')
        .find('select')
        // submit form when filter has changed
        .on('change', function () {
            var form = $(this).closest('form');

            // reset page to 1 when category or month changes
            form.find('input[name="endPage"]').val(1);

            // mark any displayed pages to be removed (since we get everything from page 1 again)
            form.find('.blog-grid__item, .blog-grid__no-items').data('remove', true);

            form.trigger('submit');
        })
        .end()
        .on('submit', function (e) {
            var filterQuery = '';

            var page = parseInt($(this).find('input[name="endPage"]').val());

            $(this).find('input[name="startPage"]').val(page - 1);

            filterQuery += 'page=' + page;

            var month = $(this).find('select[name="month"]').val();

            if (month.length > 0) {
                filterQuery += '&month=' + month;
            }

            var category = $(this).find('select[name="category"]').val();

            if (category.length > 0) {
                filterQuery += '&category=' + category;
            }

            // set URL with filters
            history.replaceState(undefined, undefined, '?' + filterQuery);
        });
}

function onBlogListCompleted() {
    // remove any blog posts that have been marked for removal
    $('.blog-grid').find('.blog-grid__item, .blog-grid__no-items').filter(function (i, el) { return $(el).data('remove') === true; }).remove();
}

function initToggleInView() {
    if ($('[data-toggle-in-view]').length === 0) {
        // nothing to toggle
        return;
    }

    $(window).on('scroll.toggle-in-view', function (e) {
        var screenBottom = $(window).scrollTop() + window.innerHeight;

        $('[data-toggle-in-view]').each(function (i, el) {
            var elem = $(el);

            if (elem.offset().top + 100 > screenBottom) {
                // element not in view yet
                return;
            }

            // toggle class
            elem.addClass(elem.data('toggle-in-view'));

            // remove attribute
            elem.removeAttr('data-toggle-in-view');
        });

        if ($('[data-toggle-in-view]').length === 0) {
            // all elements visible, remove scroll event handler
            $(window).off('scroll.toggle-in-view');
        }
    });
}

function initNewsletter() {
    $('a[href="#newsletter"]').on('click', function (e) {
        e.preventDefault();

        $('.popupform').show();
        $('.popupthankyou').hide();

        $('#newsletter-signup').foundation('open');
    });


    $('.popupform .button button').on('click', function (e) {
        e.preventDefault();

        $('.popupform').hide();
        $('.popupthankyou').show();
    });

    $('.popupthankyou .popupthanksyouclose').on('click', function (e) {
        e.preventDefault();

        $('#newsletter-signup').foundation('close');
    });


}

function initForms() {
  $('#revinate_contact_api_form').validate({
    errorPlacement: function (label, element) {
      if (element.attr('id')=='competition-form-checkbox'){
        label.insertAfter(element.next());
      } else {
        label.insertAfter(element);
      }
    }
  })
}
